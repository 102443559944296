import {updateState, state} from '@/utils.js'


const baseUrl =   document.location.origin.startsWith("http://localhost") ? "http://localhost:4401"   : document.location.origin


const getDescription = (edition) => {

    switch (edition)
    {
        case 16:
        case 24:
        case 25:
            return "pdfMachine office";
        case 17:
        case 26:
        case 27:
            return "pdfMachine pro";
        case 18:
        case 28:
        case 29:
            return "pdfMachine ultimate";
        case 41:
        case 44:
        case 45:
            return "pdfMachine ultimate";        default:
            return "pdfMachine";
    }
}

const getImageUrl = (edition) => {

    switch (edition)
    {
        case 16:
        case 24:
        case 25:
            return "@/assets/pdfmachine-office.png";

            case 17:
        case 26:
        case 27:
            return "https://www.pdfmachine.com/images/logos/pdfmachine-pro.png";
        case 18:
        case 28:
        case 29:
            return "https://www.pdfmachine.com/images/logos/pdfmachine-ultimate.png";
        case 41:
        case 44:
        case 45:
            return "https://www.pdfmachine.com/images/logos/pdfmachine-ultimate.png";
        default:
            return "https://www.pdfmachine.com/images/logos/pdfmachine-overview.png";
    }
}

const formatLicenseDetails = async (serverResponse) => {

    if (!serverResponse)
        return await updateState({
                    keyManager: {error : "Invalid Response", licenses : []}
        })

    if (serverResponse.error)
        return await updateState({
                    keyManager: {error : serverResponse.error, licenses : []}
        })

    if (!serverResponse.keyManager)
        return

    let keyManager = serverResponse.keyManager
    if (keyManager.licenses)
        for (let l of keyManager.licenses)
        {
            if (!l.users)
                l.users = []
            l.registeredUsers = l.users.filter(u => !u.removedDate);
            l.deregisteredUsers = l.users.filter(u => u.removedDate);
            l.edition = getDescription(l.productId)
            l.imageUrl = getImageUrl(l.productId)
        }

    await updateState({
        keyManager: keyManager
    })
}
const  fetchJSONPost = async (route, body) => {
    let url = baseUrl + route;
    let headers = new Headers({'Content-Type': 'application/json'});

    if (!body)
        body = {}

    body.tok =state.tok ? state.tok : ''

    let response = await fetch(url,
            {
                    method: 'post',
                    headers: headers,
                    body: JSON.stringify(body)
                })

    if (!response.ok)
    {
        await updateState({tok:''})
        console.log(`Error in fetchJSONPost ${response.statusText}`)
        return null;
    }

    const result = await response.json()

    await updateState(result)
    await formatLicenseDetails(result) //this will also update the key manager state to be in the correct format



    return result;

}



export {
    fetchJSONPost,
    formatLicenseDetails
}




