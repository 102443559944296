import {reactive} from "vue";
import router from "@/router";
import {ExportToCsv} from 'export-to-csv';


let state = reactive({
        tok: window.localStorage.getItem('tok'), //initialize from local storage
        keyManager: {
            email:'',
            admin:false,
            emailValidated:false,
            dateJoined:null,
            reseller:null,
            licenses:[],
            keyManagers:[],
        }
});

const updateState = async (newState) => {
    if (newState)
    {

        //if the tok is getting updated then check if we need to log them out
        if (Object.hasOwnProperty.call(newState, 'tok'))
        {
            if (!newState.tok || newState.tok=='')
            {
                if (state.tok)
                    console.log('logging them out because no tok ' + newState.tok)

                if (newState.error)
                    return logThemOut(false, newState.error)
                else
                    return logThemOut(false, 0)
            }
            window.localStorage.setItem('tok', newState.tok ? newState.tok : ''); //save tok to local storage
        }

        Object.keys(newState).forEach(function (key) {
            state[key] = newState[key]
        });
    }

}


const fmtDate = (d) => {
    const dt = new Date(d);
    return dt.toLocaleDateString(undefined, {year: 'numeric', month: 'long', day: 'numeric'})
}

const validateEmail = (em) => {
    if (!em)
        return false

    if (em.indexOf('@') == -1)
        return false

    if (em.indexOf('.') == -1)
        return false

    return true

}

const validatePassword = (pwd) => {
    if (!pwd)
        return false

    let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
    return re.test(pwd)

}

const isLoggedIn = async() => {
  let t = window.localStorage.getItem('tok')
  if (t)
  {
      await updateState({tok: t})
  }

  return state.tok && router.currentRoute.value.name != "ResetPass"
};


const initState = () => {
    for (const prop of Object.getOwnPropertyNames(state))
    {
        delete state[prop]
    }

    state.tok = ''
    state.keyManager = {
            email:'',
            admin:false,
            emailValidated:false,
            licenses:[],
        }

}

const logThemOut = async (fromLogout, msg) => {
    if (fromLogout)
        window.localStorage.clear(); //clear local storage
    else
        window.localStorage.removeItem('tok'); //clear local storage

    initState()

    let r = router.currentRoute.value
    if (!r || !r.meta || !r.meta.loggedOutAllowed)
    {
//        console.log("pushing login route")
        if (msg == 0)
            msg = fromLogout ? '' : "You have been logged out."
        await router.push({path: "/", query: {message: msg}})
    }
};


const exportCSV = (codes) => {
    for (let c of codes)
    {
        let data = []
        let code = c.code ? c.code : c

        const license = state.keyManager.licenses.find((l) => l.code === code)
        if (!license)
            continue

        for (let d of license.registeredUsers)
            data.push({
                code: code,
                edition: license.edition,
                name: d.name,
                devices: d.devices.join(','),
                dtDeregistered: d.removedDate ? fmtDate(d.removedDate) : ''
            })
        for (let d of license.deregisteredUsers)
            data.push({
                code: code,
                edition: license.edition,
                name: d.name,
                devices: d.devices.join(','),
                dtDeregistered: d.removedDate ? fmtDate(d.removedDate) : ''
            })
        if (data.length == 0)
            data.push({
                code: code,
                edition: license.edition,
                name: "No registered users",
                devices: '',
                dtDeregistered: ''
            })

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: `pdfMachine ${code}`,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: ['Code', 'Edition', 'Windows User Name', 'Devices', 'Date Deregistered']
        }

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
}


export  {

    fmtDate,
    updateState,
    state,
    isLoggedIn,
    logThemOut,
    exportCSV,
    validateEmail,
    validatePassword


}
