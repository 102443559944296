<template>
    <Message v-if="errorMessage"
             :severity=severity
    >{{ errorMessage }}
    </Message>
    <Card style="width: 25rem; margin-left:auto;margin-right:auto;margin-top:2em;">

        <template #title>
            Reset password for {{email}}
        </template>
        <template #content>
            <div class="p-fluid">

                <div class="p-field">
                    <label for="password">New Password</label>
                    <Password id="password" v-model="password" :feedback="false" toggleMask
                              :class="passwordClass"
                              placeholder="Password"
                    ></Password>
                    <small style="color:red;"
                       v-if="weakPassword">The password must be at least 8 characters and have an upper case letter, a lowercase letter and a numeric.
                    </small>
                </div>
                <div class="p-field" >
                    <label for="confirmpassword">Confirm Password</label>
                    <Password id="confirmpassword" v-model="confirmPassword" :feedback="false" toggleMask strong
                              :class="passwordClass"
                              placeholder="Password"
                    ></Password>
                    <small style="color:red;"
                           v-if="matchPasswords">You must enter matching passwords.
                     </small>
                </div>
            </div>
        </template>
        <template #footer>
            <Button  label="Change Password" @click="resetPwd"
                    :disabled="password.length==0 || confirmPassword.length==0 || matchPasswords || weakPassword"
                    style="float:right;"
            ></Button>
            <br/>
            <br/>
        </template>
    </Card>

</template>

<script>
import {ref, computed, onMounted} from 'vue'
import router from "@/router";
import {resetPassword} from "@/api/login";
import {validatePassword} from "@/utils";
//import {updateState} from "@/utils";


export default {
name: "ResetPass.vue"

      , props: {
        resettok: {
            type: String,
            required: true
        }
    }
    , setup(props)
    {
        let password = ref("");
        let confirmPassword = ref("");
        let loading = ref(false)
        let errorMessage = ref("")
        let severity = ref("info")
        let email = ref("")


        let passwordClass = computed(() => {
            if (password.value.length == 0 || confirmPassword.value.length == 0 || password.value !== confirmPassword.value)
                return "p-inputtext-lg p-mr-2 p-mb-2 p-invalid"
            else
                return "p-inputtext-lg p-mr-2 p-mb-2"
        })

        let matchPasswords = computed(() => {
            if (password.value.length == 0 && confirmPassword.value.length == 0)
                return false;
            return password.value !== confirmPassword.value
        })

        let weakPassword = computed(() => {
            if (password.value.length >= 0 && !validatePassword(password.value))
                return true;
            return false
        })

        onMounted(async () => {
            //get the keyManager this is for and make sure it hasn't timed out
               loading.value = true;
                const response = await resetPassword(props.resettok, "", true)
                loading.value  = false;
                if (response.error)
                {
                    errorMessage.value = response.error
                    severity.value = "error"
                    return
                }

                const keyManager = response.keyManager
                if (!keyManager || keyManager.error)
                {
                    errorMessage.value = keyManager && keyManager.error ? keyManager.error : "Unknown error"
                    severity.value = "error"
                }
                else
                    email.value = keyManager.email
        });


        return {
            password,
            confirmPassword,
            loading,
            errorMessage,
            passwordClass,
            severity,
            props,
            email,
            matchPasswords,
            weakPassword,
        }

    }

    , methods: {
        async  resetPwd()
        {
            if (this.password && this.confirmPassword)
            {
                if (this.password !== this.confirmPassword)
                {
                    this.errorMessage = "The passwords must match"
                    return;
                }

               if (!validatePassword(this.password))
               {
                   this.severity = "warning"
                   return this.errorMessage = "The password must be at least 8 characters and have an upper case letter, a lowercase letter and a numeric."
               }


                this.errorMessage = ""

//               await updateState( {tok:this.props.resettok})

               this.loading = true;
                const response = await resetPassword(this.props.resettok, this.password, false)
                this.loading = false;
                if (!response || response.error)
                {
                    this.errorMessage = response && response.error ? response.error : "Unknown error resetting password"
                    this.severity = "error"
                }
                else
                {
                    this.errorMessage = "Your password has been reset. You can now login."
                    this.severity = "info"
                    setTimeout(async () => {
                        await router.push(`/`)
                    }, 1000);
                }
            }
            else
                this.errorMessage = "You must enter a new password."

        }


    }
}
</script>

<style scoped>

</style>
