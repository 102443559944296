<template>
       <Card style="width: 25rem; margin-left:auto;margin-right:auto;margin-top:2em;">

        <template #title>
            Email Validation
        </template>
        <template #content>
            {{message}}
        </template>
        <template #footer>
            <Button
                    label="Login"
                    style="float:right;"
                    @click="router.push('/')"

            ></Button>

            <br/>
            <br/>

        </template>
    </Card>
</template>

<script>

import {onMounted, ref} from "vue"
import {validateEmail} from "@/api/login";
import router from "@/router";

export default {

      props: {
        t: {
            type: String,
            required: true
        }
    }
    , setup(props)
    {
        let message = ref("Validating Email ...")

        onMounted(async () => {
            let response = await validateEmail(props.t)
            message.value = response.keyManager && response.keyManager.error ? response.keyManager.error : "Your email has been validated. Login."
        });

        return {
            props,
            message,
            router
        }

    }
}
</script>

<style scoped>

</style>
