<template>
    <Message v-if="errorMessage"
             :severity=severity
    >{{ errorMessage }}
    </Message>
    <Card style="width: 25rem; margin-left:auto;margin-right:auto;margin-top:2em;">

        <template #title>
            Login
        </template>
        <template #content>
            <div class="p-fluid">

                <div class="p-field">
                    <label for="email">Email Address</label>
                    <InputText type="text" id="email" v-model="email"
                               placeholder="Enter Email Address"
                    >
                    </InputText>
                </div>
                <div class="p-field">

                    <label for="password">Password</label>
                    <Password id="password" v-model="password"  :feedback="false" toggleMask
                              class="p-inputtext-lg p-mr-2 p-mb-2"
                              placeholder="Password"
                    ></Password>
                </div>
            </div>
        </template>
        <template #footer>
            <Button
                label="Login" :disabled="email.length==0 || email.length > 250 ||password.length==0"
                style="float:right;"
                @click="doLogin"

            ></Button>
            <br/>
            <br/>

        </template>
    </Card>

    <div style="width:25em;margin-left:auto;margin-right:auto;text-align: center;">

        <Button class="p-button-sm p-button-text" label="Forgot Password" :disabled="email.length==0"
                @click="forgotPassword"></Button>
        <Button class=" p-button-sm p-button-text " label="Resend Validation Email"
                :disabled="email.length==0 || password.length==0 " @click="resendValidation"
                v-if="validationRequired"
        >
        </Button>
        <p>Don't have an account? Sign up
            <router-link to="signup">
                here
            </router-link>
        </p>
    </div>

    <ProgressSpinner v-if="loading"/>

</template>

<script>

import {onMounted, ref} from 'vue'
import {login, sendEmailValidation, requestResetPassword} from "@/api/login";
import router from "@/router";
import {validateEmail} from "@/utils";

export default {

    props: {
        message: {
            type: String,
            required: false
        }

    }
    , setup(props)
    {
        let loading = ref(false)

        let email = ref('');
        let password = ref('');
        let errorMessage = ref(props.message ? props.message : '')
        let severity = ref("error")
        let validationRequired = ref(false);
        let validationSent = ref(false);

        onMounted(async () => {
            let keytoken = window.localStorage.getItem('keytoken');
            if (keytoken && !props.message)
            {
                severity.value = "info"
                errorMessage.value = "Login to add the key to your pdfMachine Key Management Portal"
            }

        });


        return {
            email,
            password,
            loading,
            errorMessage,
            severity,
            validationRequired,
            validationSent,
            props
        }
    }

    , methods: {
        async doLogin()
        {

            if (this.email && this.password)
            {
                if (!validateEmail(this.email))
                {
                    this.severity = "error"
                    this.errorMessage = "You must enter a valid email address"
                    return;
                }

                this.loading = true;
                const response = await login(this.email, this.password)
                this.loading = false;
                this.errorMessage = response ?  (response.keyManager && response.keyManager.error ? response.keyManager.error : (response.error ? response.error : "")) : "No response  from server"
                this.severity = "error"

                if (!response)
                    return;

                if (response && response.keyManager && !response.keyManager.emailValidated)
                {
                    this.validationRequired = true;
                    return;
                }

                if (response.error || this.errorMessage)
                    return

                await router.push(`/licenses`);

            }

        }

        , async resendValidation()
        {

            if (this.email && this.password)
            {
                this.validationRequired = false;
                this.validationSent = true;
                this.errorMessage = "A welcome email has been sent to " + this.email + ". Please click on the link in the email right now."
                this.severity = "info"
                await sendEmailValidation(this.email, this.password)
            }

        }

        , async forgotPassword()
        {
            if (!this.email)
            {
                this.errorMessage = "Enter the email you used to sign up before resetting the password."
                this.severity = "error"
                return;
            }

            this.loading = true;
            const response = await requestResetPassword(this.email)
            this.loading = false;
            if (!response || response.error)
            {
                this.errorMessage = response && response.error ? response.error : "Unknown error resetting password"
                this.severity = "error"
            }
            else if (response.keyManager && response.keyManager.error)
            {
                this.errorMessage = response.keyManager.error
                this.severity = "error"
            }
            else
            {
                this.errorMessage = `An email has been sent to ${this.email}. Follow the instructions in the email to reset your password.`;
                this.severity = "info"
            }

        }

    }
}
</script>

<style scoped>

</style>
