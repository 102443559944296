<template>
    <Message v-if="errorMessage"
             :severity=severity
    >{{ errorMessage }}
    </Message>

    <Card style="width: 25rem; margin-left:auto;margin-right:auto;margin-top:2em;">

        <template #title>
            Sign Up
        </template>
        <template #content>
            <div class="p-fluid">

                <div class="p-field" >
                    <label for="email">Email Address</label>
                    <InputText type="text" id="email"  v-model="email"
                               placeholder="Enter Email Address"
                    >

                    </InputText>
                </div>
                <div class="p-field" >
                    <label for="password">Password</label>
                    <Password id="password"
                              v-model="password"
                              toggleMask
                              placeholder="Password"
                              :feedback="false"
                    >
                    </Password>
                    <small style="color:red;"
                       v-if="weakPassword">The password must be at least 8 characters and have an upper case letter, a lowercase letter and a numeric.
                    </small>
                </div>
                <div class="p-field" >
                    <label for="confirmpassword">Confirm Password</label>
                    <Password id="confirmpassword" v-model="confirmPassword" :feedback="false"
                              toggleMask
                              placeholder="Password"
                    ></Password>
                    <small style="color:red;"
                       v-if="matchPasswords">You must enter matching passwords.
                    </small>
                </div>
            </div>
        </template>
        <template #footer>

            <Button
                label="Create Account"
                @click="doSignup"
                style="float:right;"
                :disabled="email.length==0 || password.length==0 || confirmPassword.length==0 || password!==confirmPassword"
            ></Button>
            <br/>
            <br/>
        </template>
    </Card>
    <div style="width:25em;margin-left:auto;margin-right:auto;text-align: center;" >

                <Button class=" p-button-sm p-button-text " label="Resend Validation Email"
                        :disabled="email.length==0 || password.length==0 " @click="resendValidation"
                        v-if="validationRequired"
                >
                </Button>
    </div>


    <!--  Validate Email dialog -->
    <Dialog v-model:visible="signingUp" :style="{width: '750px'}"
            :showHeader = "false"
            :closable="false"
            :modal="true">
    <div class="p-fluid p-d-flex p-flex-column p-jc-center p-ai-center p-mt-2">
        <div>
                    <h1>Validate your email to complete sign up</h1>
        </div>

                <div>A welcome email has been sent to {{ email }}.</div>
                    <br/>

                   <div>Click on the link in the email to validate that you can login.</div>
                    <br/>
                <div>If the email did not arrive then check your spam folder.</div>
        </div>
        <template #footer>
            <Button label="OK"  @click="validateEmail()"
                    autofocus/>
        </template>
    </Dialog>
</template>

<script>

import {ref, onMounted, computed} from 'vue'
import router from "@/router";
import {login, sendEmailValidation, signup} from "@/api/login";
import {updateState, validateEmail, validatePassword} from '@/utils.js';

export default {
    name: "SignUp.vue"


    , setup()
    {
        let signingUp=ref(false)
        let email = ref('');
        let password = ref("");
        let confirmPassword = ref("");
        let loading = ref(false)
        let errorMessage = ref('')
        let severity = ref("error")
        let validationRequired = ref(false)



        let matchPasswords = computed(() => {
            if (password.value.length == 0 && confirmPassword.value.length == 0)
                return false;
            return password.value !== confirmPassword.value
        })

        let weakPassword = computed(() => {
            if (password.value.length >= 0 && !validatePassword(password.value))
                return true;
            return false
        })

        onMounted(async () => {
            await updateState({tok:""})
        });

        return {
            email,
            password,
            confirmPassword,
            validationRequired,
            loading,
            errorMessage,
            signingUp,
            matchPasswords,
            weakPassword,
            severity
        }

    }

    , methods: {
        async doSignup()
        {

            if (this.email && this.password && this.confirmPassword)
            {
                if (!validateEmail(this.email))
                    return this.errorMessage = "You must enter a valid email address"

                if (!validatePassword(this.password))
                    return this.errorMessage = "The password must be at least 8 characters and have an upper case letter, a lowercase letter and a numeric."



                if (this.password !== this.confirmPassword)
                {
                    this.errorMessage = "The passwords must match"
                    return;
                }

                this.errorMessage = ""

                this.loading = true;
                const response = await signup(this.email, this.password)
                this.loading = false;
                if (!response || response.error)
                {
                    this.errorMessage = response && response.error ? response.error : "Unknown error logging in"

                    if (response.validationRequired)
                        this.validationRequired = true;
                }
                else
                {
                    if (response.keyManager)
                    {
                        this.signingUp = true;
                        await updateState(response);
                    }
                    else
                        this.errorMessage = `Unable to retrieve account for ${this.email}.`
                }
            } else
                this.errorMessage = "You must enter both an email and a password."

        }

        , async validateEmail()
        {
            this.signingUp = false;
             this.loading = true;
            const response = await login(this.email, this.password)
            this.loading = false;
            this.errorMessage = response && response.keyManager && response.keyManager.error ? response.keyManager.error : ""
            if (! response )
              return;

            if (response && response.keyManager && !response.keyManager.emailValidated)
            {
                this.errorMessage = "You must validate your email. Check your spam folder if you can't find it."
              this.validationRequired = true;
              return;
            }

            if (response.error || this.errorMessage)
                return

            await router.push(`/licenses`);
        }

        , async resendValidation()
        {

            if (this.email && this.password)
            {
                this.validationRequired = false;
                this.errorMessage = "A welcome email has been sent to " + this.email + ". Please click on the link in the email to complete account creation."
                this.severity = "info"
                await sendEmailValidation(this.email, this.password)
                this.signingUp=true
            }

        }

    }
}
</script>

<style scoped>


</style>
