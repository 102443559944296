<template>
    <Dialog v-model:visible="display" :style="{width: '700px'}" header="Enter your pdfMachine registration key"
            :modal="true"
            @close="close"
    >
            <Toast/>
        <div>
            <div class="p-fluid p-form p-grid p-m-2">
                <div class="p-field p-sm-12 p-md-6 p-lg-6">
                    <label for="regokey">Enter License Key</label>
                    <InputText type="text" id="regokey" class=" p-mr-2 p-mb-2" v-model="regokey"
                               placeholder="Enter License Key"
                    >

                    </InputText>
                </div>
                <!--div class="p-field p-sm-12 p-md-6 p-lg-6">
                  <label for="password">Enter Password</label>
                </div>
                <p>Contact Broadgun Software if you do not have a password for your registration key</p-->
            </div>

            <ProgressSpinner v-if="loading"/>
        </div>
        <template #footer>
            <Button label="Manage Key"
                    @click="addLicense"
                    :disabled="loading"
                    autofocus/>
            <Button label="Cancel" @click="close"
                    :disabled="loading"
                    />
        </template>
    </Dialog>
</template>

<script>
import {onMounted, ref, watch} from 'vue'
import {useToast} from "primevue/usetoast";
import {addLicenseDetails} from '@/api/licenses'
import {state} from '@/utils.js'


export default {
    props: {
        addKeyDialog: {
            type: Boolean,
            required: true
        },

        licenses: {
            type: Array,
            required: true
        }
    }

    , emits: ['keyAdded', 'update:addKeyDialog']

    , setup(props, {emit})
    {
        const toast = useToast()
        let loading = ref(false)
        let regokey = ref('')
        let display = ref(props.addKeyDialog)

        onMounted(async () => {
        })

        const addLicense = async () => {
            //make sure we haven't already got it in our list
            if (props.licenses && props.licenses.find((l) => l.code === regokey.value))
            {
                toast.add({
                    severity: 'error',
                    summary: 'Failed',
                    detail: "This key is already managed by you",
                    life: 3000
                });
                return;
            }

            if (regokey.value)
            {
                loading.value = true
                await addLicenseDetails(regokey.value)
                loading.value = false

                if (state.keyManager && state.keyManager.error)
                    toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
                else
                {
                    close()
                    emit('keyAdded', true)
                }
            }
        }

        const close = () => {
            display.value = false
            emit('update:addKeyDialog', false)
        }


        watch(() => props.addKeyDialog, (value) => {
            display.value = value
        })

        return {
            props,
            loading,
            toast,
            regokey,
            addLicense,
            display,
            close

        }

    }


}
</script>

<style scoped>

</style>

