import { fetchJSONPost } from './utils.js'
import {state} from '@/utils.js'


const retErr = (msg) =>{
    if (!state.keyManager)
        state.keyManager = {}
    state.keyManager.error = msg
}

const loadKeyManager = async () => {

    if (!state.tok)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/get-key-manager", {})

    if (!response)
        return retErr("Unknown error from server")

    return response

}

const addLicenseDetails = async (code) =>  {

    if (!code)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/manage-key", {"code": code})

    if (!response)
        return retErr("Unknown error from server")

    return response;


}

const applyKeyToken = async (keytoken) =>  {

    if (!keytoken)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/apply-key-token", {"keytoken": keytoken})

    if (!response)
        return retErr("Unknown error from server")

    return response;


}


const removeLicenseDetails = async(code) => {

    if (!code)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/remove-key", {"code": code})

    if (!response)
        return retErr("Unknown error from server")

    return response;


}

const getShareLink = async(shareCode, shareEmail, expiryDays) => {

    if (!shareCode || !shareEmail )
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/get-share-link", {"code": shareCode, "shareEmail":shareEmail, "expiryDays":expiryDays})

    if (!response)
        return retErr("Unknown error from server")

    return response;


}

const combineLicenseDetails = async(code, vpKeys) => {
    if (!code || !vpKeys)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/combine-keys", {"code": code, "vpKeys": vpKeys})

    if (!response)
        return retErr("Unknown error from server")

    return response;
}

const removeUser = async(codes, users) =>  {

    if (!codes || !users)
        return retErr("Invalid parameters")

    let response = await fetchJSONPost("/remove-user", {"codes": codes, "users":users})

    if (!response)
        return retErr("Unknown error from server")

    return response;


}

export  {
    loadKeyManager,
    addLicenseDetails,
    removeLicenseDetails,
    removeUser,
    combineLicenseDetails,
    applyKeyToken,
    getShareLink
}


