import {createApp} from 'vue'
import App from './App.vue'
//import App from './test.vue'

import router from './router'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import Menubar from "primevue/menubar";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Toast from 'primevue/toast';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import Divider from 'primevue/divider';

import './customStyles.css'

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.use(router);

app.use(ConfirmationService);


app.component("Menubar", Menubar);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('Card', Card);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('SelectButton', SelectButton);
app.component('Toolbar', Toolbar);
app.component('Dialog', Dialog);
app.component('Toast', Toast);
app.component('ScrollPanel', ScrollPanel);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Message', Message);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Divider', Divider);





app.mount('#app');


