<template>

    <div id="app">
        <MainMenu></MainMenu>
        <router-view/>
    </div> <!-- app -->

</template>

<script>

import MainMenu from "@/components/MainMenu";


export default {
    components: {
        MainMenu
    }

    , setup()
    {
        return {}

    }


}

</script>



