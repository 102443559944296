<template>
    <!-- Not using the menu bar because I didn't have any items and it put a hamburger in when on small screen, maybe a good idea if we have more menu items -->
    <!--Menubar
        :v-model="items"
    >
        <template #start>
            <Button title="Back" icon="pi pi-chevron-left"
                    large
                    :class="backClass"
                    style="height:4rem;width:4rem;position:relative;top:-7px;"
                    @click="back"
            />
            <img alt="logo" src="/logo.svg" height="75" class="p-p-2">
            <span  style="position:relative;top:-30px;right:-30px;">{{state.keyManager.email}}</span>
        </template>

        <template #end>
            <div>
                <Button label="Login" :style="{'margin-left': '0 .5em'}"
                        :class="loginClass"
                        @click="router.push('/')"
                />
                <Button label="Sign Up" :style="{'margin-left': '0 .5em'}"
                        :class="signupClass"
                        @click="router.push('/signup')"
                />
                <Button label="Logout" :style="{'margin-left': '0 .5em'}"
                        :class="logoutClass"
                        @click="logout"
                />
            </div>
        </template>
    </Menubar-->


    <!-- jens home mode menubar -->
        <div class="p-d-flex p-jc-between p-ai-center" style="background-color:rgb(248, 249, 250);border-bottom: 3px solid rgb(222, 226, 230);">
            <div class="p-ml-2 ">
                <Button title="Back" icon="pi pi-chevron-left"
                        large
                        :class="backClass"
                        style="height:4rem;width:4rem;position:relative;top:-7px;"
                        @click="back"
                />
                <img alt="logo" src="/logo.svg" height="75" class="p-p-2">
                <span  style="position:relative;top:-30px;right:-30px;">{{state.keyManager.email}}</span>
            </div>
            <div class="p-mr-2 ">
                <Button label="Admin" :style="{'margin-left': '0 .5em'}"
                        :class="adminClass"
                        @click="router.push('/admin')"
                />
                <Button label="Login" :style="{'margin-left': '0 .5em'}"
                        :class="loginClass"
                        @click="router.push('/')"
                />
                <Button label="Sign Up" :style="{'margin-left': '0 .5em'}"
                        :class="signupClass"
                        @click="router.push('/signup')"
                />
                <Button label="Logout" :style="{'margin-left': '0 .5em'}"
                        :class="logoutClass"
                        @click="logout"
                />
            </div>        </div>


</template>

<script>
import {computed} from "vue"
import router from "@/router"
import {logThemOut, state} from '@/utils.js'

export default {

    setup()
    {
        //can't use the async isLoggedIn here - it shouldn't need to get from local storage so it shouldn't be a problem
        let buttonOnClass = 'p-button-rounded p-mr-2'
        let buttonOffClass = 'p-button-rounded p-mr-2 p-d-none'

        let loginClass = computed(() => {return router.currentRoute.value.name === "Reset Password" || router.currentRoute.value.name === "SignUp"  ? buttonOnClass  : buttonOffClass})
        let licenseClass = computed(() => { return (state.tok && router.currentRoute.value.name !== "ResetPass" && router.currentRoute.value.name !== "Licenses") ? buttonOnClass : buttonOffClass})
        let logoutClass = computed(() => {return router.currentRoute.value.name !== "Validate Email" && router.currentRoute.value.name !== "Reset Password" && router.currentRoute.value.name !== "Login" && router.currentRoute.value.name !== "SignUp" ? buttonOnClass : buttonOffClass})
        let adminClass =  computed(() => {return state.keyManager && state.keyManager.admin && router.currentRoute.value.name !== "Key Manager Admin" ? logoutClass.value : buttonOffClass})
        let signupClass = computed(() => {return router.currentRoute.value.name === "Reset Password" || router.currentRoute.value.name === "Login" || router.currentRoute.value.name === "Validate Email"? buttonOnClass : buttonOffClass})
        let backClass = computed(() => {return router.currentRoute.value.name === "Key Manager Admin" || router.currentRoute.value.name === "Reset Password" || router.currentRoute.value.name === "SignUp" || router.currentRoute.value.name === "Users"  ? buttonOnClass  + " p-button-outlined" : buttonOffClass})
        const back = () => {
            const rt =  router.currentRoute.value.name === "Users"  || router.currentRoute.value.name === "Key Manager Admin" ? "/licenses" : "/"
            router.push(rt)
        }

        const logout = async () => {
            await logThemOut(true, "")
        }

        let items = []



        return {
            items,
            state,
            router,
            logout,
            licenseClass,
            backClass,
            signupClass,
            logoutClass,
            back,
            loginClass,
            adminClass
        }
    }

}
</script>

<style scoped>

</style>
