//import { h } from 'vue'
import {createWebHistory, createRouter, useRouter, useRoute} from "vue-router";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import ResetPass from "../views/ResetPass.vue";
import ValidateEmail from "../views/ValidateEmail.vue";
import Licenses from "../views/Licenses.vue";
import Users from "../views/Users.vue";
import KeyManagers from "../views/KeyManagers.vue";
import NotFound from "../views/NotFound.vue";
import {isLoggedIn, logThemOut, state} from "@/utils";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {loggedOutAllowed: true},
        props: route => ({message: route.query.message}),
        beforeEnter: async (to, from, next) => {
            await beforeLogin(to, from, next)
        }
    },
    {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        meta: {loggedOutAllowed: true}
    },
    {
        path: "/reset-pass/",
        name: "Reset Password",
        component: ResetPass,
        props: route => ({resettok: route.query.resettok}),
        meta: {loggedOutAllowed: true},
        // redirect: to => {
        //     // the function receives the target route as the argument
        //     // we return a redirect path/location here.
        //     return {path: '/', query: {resettok: to.query.resettok}}
        // },
    },
    {
        path: "/licenses",
        name: "Licenses",
        component: Licenses,
        meta: {loggedOutAllowed: false},
        beforeEnter: async (to, from, next) => {
            await beforeLoggedInRoutes(to, from, next)
        },
    },
    {
        path: "/admin",
        name: "Key Manager Admin",
        component: KeyManagers,
        meta: {loggedOutAllowed: false},
        beforeEnter: async (to, from, next) => {
            await beforeLoggedInRoutes(to, from, next)
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {loggedOutAllowed: false},
        props: route => ({code: route.query.code}),
        beforeEnter: async (to, from, next) => {
            await beforeLoggedInRoutes(to, from, next)
        },
    },
    {
        path: "/validate-email",
        name: "Validate Email",
        component: ValidateEmail,
        props: route => ({t: route.query.t}),
        meta: {loggedOutAllowed: true}
    },
    {
        path: "/key-token",
        name: "Key Token",
        redirect: to => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            if (to.query && to.query.keytoken)
                window.localStorage.setItem('keytoken', to.query.keytoken);
            let t = window.localStorage.getItem('tok');
            if (t)
                return {path: '/licenses'}
            else
                return {path: '/', query: {message: ''}}
        },
        props: route => ({keytoken: route.query.keytoken}),
    },
    {
        path: "/:catchAll(.*)",
        name: "Notfound",
        component: NotFound,
        meta: {loggedOutAllowed: false},
        beforeEnter: async (to, from, next) => {
            await beforeLoggedInRoutes(to, from, next)
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


const beforeLogin = async (to, from, next) => {
    //if they are logged in take them to the licenses page instead  - this means they get in when they shouldn't eg email not validated.
    // if (await isLoggedIn())
    //     return next({path: '/licenses'})
    return next()
}

const beforeLoggedInRoutes = async (to, from, next) => {
    if (!await isLoggedIn())
    {
        //we are not logged in
        if (!to.meta.loggedOutAllowed)
        {
            next(false)
            console.log('logthemout before route')
            if (state.keyManager && state.keyManager.error)
                return logThemOut(false, state.keyManager.error)
            else
                return logThemOut(false, 0)
        }
    }

    return next();
}

export {router as default, useRouter, useRoute}
