import { fetchJSONPost } from './utils.js'
import {state} from '@/utils.js'


const retErr = (msg) =>{
    if (!state.keyManager)
        state.keyManager = {}
    state.keyManager.error = msg
}

const loadKeyManagers = async () => {

    if (!state.tok)
        return retErr("Invalid parameters")

    let body = {}
    let response = await fetchJSONPost("/get-key-managers", body)

    if (!response)
        return retErr("Unknown error from server")

    return response

}

export  {
    loadKeyManagers,
}


