<template>
    <div>
        <Card
            v-if="state.keyManager.admin"
        >

            <template #content>
                <Toast/>

                <DataTable
                    ref="kms"
                    :value="keyManagers"
                    class="p-datatable-striped"
                    :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50,100]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading"
                    v-model:filters="filters"
                    :globalFilterFields="['email', 'licenseStr']" responsiveLayout="scroll"

                    sortField="dateJoined" :sortOrder="-1"
                    :row-class="rowClass"
                >
                    <template #header>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        <span class="p-input-icon-left " >
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..."
                                     style="width:30em;"

                            />
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
                        </span>

                        <Button title="Export All to CSV" icon="pi pi-download"
                                class="p-mr-2 p-button-info p-button-rounded"
                                @click="exportKms($event)"
                                :disabled="loading || keyManagers.length > 0 "
                        />
                      </div>
                     </template>

                    <template #loading>
                        Loading Key Managers. Please wait.
                    </template>

                    <Column field="email" header="Email" sortable></Column>
                    <Column field="emailValidated" header="Validated" sortable></Column>
                    <Column field="dateJoined" header="Date Joined"
                            sortable
                            exportable
                    >
                        <template #body="slotProps">
                            <span >{{ fmtDate(slotProps.data.dateJoined) }} </span>
                        </template>
                    </Column>
                    <Column field="licenseStr" header="Licenses"
                            sortable
                            exportable
                    >
                    </Column>
                    <!--Column field="reseller" header="reseller" sortable></Column-->
                    <!--Column :exportable="false">
                        <template #body="slotProps">
                            <Button icon="pi pi-users" title="View Users" class="p-button-rounded p-button-info p-mr-2" @click="editKey(slotProps.data)" :disabled = "loading"/>
                            <Button icon="pi pi-trash" title="Remove" class="p-button-rounded p-button-info p-mr-2" @click="removeLicense(slotProps.data)"  :disabled = "loading"/>

                            <Button v-if="slotProps.data.vpKeys !== ''"
                                label="Combine"
                                title="Combine version protection keys into main key"
                                class="p-button-rounded p-button-info  p-mr-2"
                                @click="combineKeys(slotProps.data)"  :disabled = "loading"

                            />
                       </template>
                    </Column-->
                </DataTable>

            </template>
        </Card>

    </div>
</template>
<style>
.reseller-class {
    background-color: #e9ecef  !important;
    color: #000000 !important;
}
</style>

<script>
import {useToast} from "primevue/usetoast";
import {loadKeyManagers} from '@/api/admin'
import {onMounted, reactive, ref} from "vue";
import {state, fmtDate} from "@/utils";
import {FilterMatchMode} from "primevue/api";

export default {
    name: "KeyManagers.vue"

    , setup()
    {
        const toast = useToast()
        const keyManagers = reactive([])
        let loading = ref(false)
        let filters = ref({
            global: {value: '', matchMode: FilterMatchMode.CONTAINS}
        });

        onMounted(async () => {
            await loadKeyManagers()

            if (state.keyManager.keyManagers)
                for (let km of state.keyManager.keyManagers)
                {
                    km.licenseStr = km.licenses.join(',')
                    keyManagers.push(km)
                }
        })

        return {
            toast,
            keyManagers,
            state,
            loading,
            filters,
            fmtDate
        }
    }

    , methods: {
        exportKms()
        {
            this.$refs.kms.exportCSV();
        },

        clearFilter()
        {
          this.filters['global'].value = ''

          },

        rowClass(data)
        {
            return data.reseller ? 'reseller-class' : ''
        }

    }
}
</script>

<style scoped>

</style>
