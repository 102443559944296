<template>

    <Card v-if="license && license.code && license.code.length > 0">
        <template #header>
        </template>
        <template #title>
            <div>{{ license.edition }}</div>
            <div>{{ license.code }}</div>
        </template>
        <template #subtitle>
            <div class="p-mt-2 p-mb-2">

                <div v-if="license.vpKeys">
                    <p><span v-if="license.upgradeKey">upgraded by {{ license.upgradeKey }}<br/></span>with version protection keys {{ license.vpKeys }}</p>
                    <Divider />
                </div>
                <div>Users : {{ license.registeredUsers.length }} of {{ license.numInstalls }}</div>
                <div class="p-mb-2">Version Protection to {{ fmtDate(license.expiry) }}</div>
            </div>
        </template>

        <template #content>

            <ConfirmDialog></ConfirmDialog>
            <TabView
                @tab-change="filters['global'].value=''"
            >


                <TabPanel>
                    <template #header>
                        <span>Registered Users</span>
                    </template>
                    <DataTable
                        :value="license.registeredUsers"
                        ref="dtUsers"
                        class="p-datatable-striped"
                        :paginator="true" :rows="10"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        :rowsPerPageOptions="[10,20,50,100]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :loading="loading"
                        v-model:selection="selectedUsers" selectionMode="multiple" dataKey="name"
                        v-model:filters="filters"
                        :globalFilterFields="['name', 'devices']" responsiveLayout="scroll"
                    >

                        <template #header>
                            <span class="p-input-icon-left">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filters['global'].value" placeholder="Search..."
                                               style="width:30em;"
                                               type="search"
                                    />
                            </span>
                            <div style="float:right;">
                                <Button label="Deregister" title="Deregister users" icon="pi pi-trash"
                                        class="p-button-info p-button-rounded p-mr-2"
                                        @click="deregisterUsersDialog = true"
                                        :disabled="!selectedUsers || !selectedUsers.length || loading"/>
                                <Button title="Export All to CSV" icon="pi pi-download"
                                        class="p-mr-2 p-button-info p-button-rounded"
                                        @click="exportCSV([license.code])"
                                        :disabled="loading"
                                />
                            </div>
                        </template>

                        <template #loading>
                            Loading user data. Please wait.
                        </template>

                        <Column selectionMode="multiple" headerStyle="width: 3em" exportable></Column>
                        <Column field="name" header="Windows User Name" sortable exportable></Column>
                        <Column field="devices" header="Devices" exportable>
                            <template #body="slotProps">
                                {{ slotProps.data.devices.join(', ') }}
                            </template>
                        </Column>
                    </DataTable>
                </TabPanel>
                <TabPanel >
                    <template #header>
                        <span>Deregistered Users</span>
                    </template>
                    <!--  Deregistered Users -->
                    <div v-if="license.deregisteredUsers.length == 0">No users have been deregistered</div>
                    <DataTable v-else
                        :value="license.deregisteredUsers"
                        ref="dtDeregistered"
                        class="p-datatable-striped"
                        :paginator="true" :rows="10"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        :rowsPerPageOptions="[10,20,50,100]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :loading="loading"
                        v-model:filters="filters"
                        :globalFilterFields="['name', 'devices']" responsiveLayout="scroll"
                       sortField="removedDate" :sortOrder="-1"
                    >

                         <template #header>
                            <span class="p-input-icon-left">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filters['global'].value" placeholder="Search..."
                                               style="width:30em;"
                                               type="search"

                                    />
                            </span>
                            <div style="float:right;">
                                <Button title="Export CSV" icon="pi pi-download"
                                        class="p-mr-2 p-button-info p-button-rounded"
                                        @click="exportCSV([license.code])"
                                        :disabled="loading"
                                />
                            </div>
                        </template>

                        <template #loading>
                            Loading user data. Please wait.
                        </template>

                        <Column field="name" header="Windows User Name"
                                sortable
                                exportable
                        ></Column>
                        <Column field="removedDate" header="Date Deregistration"
                                sortable
                                exportable
                        >
                            <template #body="slotProps">
                                <span class="deactivated">{{ fmtDate(slotProps.data.removedDate) }} </span>
                            </template>
                        </Column>

                    </DataTable>
                </TabPanel>
            </TabView>
            <Toast/>


        </template>
    </Card>
    <!--  Confirmation dialog -->
    <Dialog v-model:visible="deregisterUsersDialog" :style="{width: '550px'}" :show-header="true"
            :closable="false"
            :modal="true"
            class="p-mt-2"
    >
        <template #header>
            <h1>Are you sure you want to deregister these users?</h1>
        </template>
        <div>The listed users will be permanently deregistered from the key(s) <br/>{{license.code}}<span v-if="license.upgradeKey">,{{license.upgradeKey}}</span><span v-if="license.vpKeys">,{{license.vpKeys}}</span>.&nbsp;The deregistered users will not be able to register to use this license again.</div>
        <br/>
        <div >This will free the license up for use by another user. They must register using the key(s).</div>
        <Divider/>
        <div ></div>
        <br/>
        <ScrollPanel style="width: 100%; height: 200px;" class="custom p-mt-4 p-text-left">
            <div v-for="u in selectedUsers" :key="u.name">{{ u.name }}</div>
        </ScrollPanel>
        <template #footer>
            <Button label="Yes"
                    @click="deregisterUsers"
                    :disabled="loading"
            />
            <Button label="No"
                    @click="deregisterUsersDialog = false"
                    autofocus :disabled="loading"/>
        </template>
    </Dialog>


</template>

<script>

import {onMounted, reactive, ref} from 'vue'
import {FilterMatchMode} from "primevue/api";
import {state, fmtDate, exportCSV} from '@/utils.js'
import router from "@/router";
import {useToast} from "primevue/usetoast";
import {loadKeyManager, removeUser} from "@/api/licenses";


export default {

    props: {
        code: {
            type: String,
            required: true
        },
        vpKeys: {

        }
    }

    , setup(props)
    {
        let loading = ref(false)
        const toast = useToast();


        let filters = ref({
            global: {value: '', matchMode: FilterMatchMode.CONTAINS},
        });

        let selectedUsers = ref([]);
        let deregisterUsersDialog = ref(false);

        const resetUsers = () => {
            if (!state.keyManager || !state.keyManager.licenses)
                return router.push("/licenses")

            const thisLicense = state.keyManager.licenses.find((l) => l.code === props.code)
            if (!thisLicense)
                return router.push("/licenses")

            for (let x in thisLicense)
                license[x] = thisLicense[x]

        }


        const deregisterUsers = async () => {
            let deleteList = selectedUsers.value.map(val => val.name);
            loading.value = true
            let s = license.code
            s += license.upgradeKey ? `,${license.upgradeKey}` : ''
            s += license.vpKeys ? `,${license.vpKeys}` : ''
            await removeUser( s, deleteList)
            loading.value = false

            if (state.keyManager && state.keyManager.error)
            {
                toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
                return;
            }
            toast.add({severity: 'success', summary: 'Successful', detail: ' Users Deregistered', life: 3000});
            resetUsers()
            selectedUsers.value = [];
            deregisterUsersDialog.value = false;
        }

        const dtUsers = reactive({});
        const dtDeregistered = reactive({});
        let license = reactive({})

        onMounted(async () => {
            loading.value = true
            await loadKeyManager()
            resetUsers()
            loading.value = false
        })


        return {
            loading,
            toast,

            license,
            filters,
            dtUsers,
            dtDeregistered,

            selectedUsers,

            deregisterUsersDialog,
            deregisterUsers,

            fmtDate,
            exportCSV
        }

    }

}
</script>

