<template>
    <div>
    <Card>

        <template #content>
            <ConfirmDialog></ConfirmDialog>
            <Toast/>

            <DataTable
                ref="lics"
                :value="licenses"
                class="p-datatable-striped"
                :paginator="true" :rows="10"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :loading="loading"
                v-model:filters="filters"
                :globalFilterFields="['code', 'edition', 'vpKeys']" responsiveLayout="scroll"
            >
                <template #header>
                  <h5 class="p-m-0">Manage Licenses</h5>
                  <div class="p-d-flex p-jc-between p-ai-center">
                        <span class="p-input-icon-left " >
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..."
                                       style="width:30em;"

                            />
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
                        </span>

                    <Button v-if="state.keyManager.admin" title="Manage Another License" label = "Manage Another Key" icon="pi pi-plus" class="p-button-info p-button-rounded p-mr-2"
                            @click="addKeyDialog=true"
                            :disabled = "loading"
                    />

                    <Button title="Export All to CSV" icon="pi pi-download"
                            class="p-mr-2 p-button-info p-button-rounded"
                            @click="exportLicenses(licenses)"
                            :disabled="loading || licenses.length == 0"
                    />
                  </div>
                    <!--div class="table-header">
                        <h5 class="p-m-0">Manage Licenses</h5>
                        <Toolbar class="p-mb-4">
                            <template #left>
                                <span class="p-input-icon-left">
                                        <i class="pi pi-search"/>
                                        <InputText v-model="filters['global'].value" placeholder="Search..."
                                                   style="width:30em;"
                                                   type="search"
                                        />
                                </span>
                            </template>

                            <template #right>
                                <Button v-if="state.keyManager.admin" title="Manage Another License" label = "Manage Another Key" icon="pi pi-plus" class="p-button-info p-button-rounded p-mr-2"
                                        @click="addKeyDialog=true"
                                        :disabled = "loading"
                                />

                                <Button title="Export All to CSV" icon="pi pi-download"
                                        class="p-mr-2 p-button-info p-button-rounded"
                                        @click="exportLicenses(licenses)"
                                        :disabled="loading || licenses.length == 0"
                                />
                            </template>
                        </Toolbar>
                    </div-->
                </template>

                <template #loading>
                    Loading licenses. Please wait.
                </template>

                <Column field="code" header="License Key" sortable>
                    <template #body="slotProps">
                      {{slotProps.data.code}} <span v-if="slotProps.data.upgradeKey"><br/><sub>upgraded by {{slotProps.data.upgradeKey}}</sub></span>
                    </template>

                </Column>
                <Column field="edition" header="Edition" sortable></Column>
                <Column field="expiry" header="Version Protection Expiry" sortable>
                    <template #body="slotProps">
                      {{fmtDate(slotProps.data.expiry)}}
                    </template>

                </Column>
                <Column field="vpKeys" header="Version Protection Keys">
                </Column>
                <Column :exportable="false">
                    <template #body="slotProps">
                        <Button icon="pi pi-users" title="View Users" class="p-button-rounded p-button-info p-mr-2" @click="editKey(slotProps.data)" :disabled = "loading"/>
                        <Button icon="pi pi-trash" title="Remove" class="p-button-rounded p-button-info p-mr-2" @click="removeLicense(slotProps.data)"  :disabled = "loading"/>
                        <!--Button v-if="state.keyManager.admin"-->
                        <Button
                            icon="pi pi-share-alt"
                            title="Invite another to manage this key"
                            class="p-button-rounded p-button-info  p-mr-2"
                            @click="share(slotProps.data)"  :disabled = "loading"

                        />
                        <Button v-if="slotProps.data.vpKeys !== '' && !slotProps.data.upgradeKey"
                            label="Combine"
                            title="Combine version protection keys into main key"
                            class="p-button-rounded p-button-info  p-mr-2"
                            @click="promptCombine(slotProps.data)"  :disabled = "loading"

                        />
                   </template>
                </Column>
            </DataTable>

        </template>
    </Card>
    <!--  Add Key dialog -->
    <ManageKey
        v-model:addKeyDialog = "addKeyDialog"
        v-model:licenses = "licenses"
        @key-added="resetLicenses"
    ></ManageKey>

   <Dialog v-model:visible="showCombine" :style="{width: '400px'}" :show-header="true" :closable="false"
            :modal="true" header="Combine to a single key"
    >
        <!--template #header>
            <h3>Combine to a single key</h3>
        </template-->
       <div>The following keys will be combined to the single key {{combineKeys[0].code}}.</div><br/>
       <div>Version protection will expire on {{fmtDate(combineExpiry)}}.</div>
       <DataTable
            :value="combineKeys"
            class="p-datatable-sm p-mt-1"
       >
            <Column field="code" header="Code" ></Column>
       </DataTable>

        <template #footer>
           <Button label="Combine"   @click="doCombineKeys"
            />
            <Button
                    label="Cancel"
                    @click="closeCombine"

            ></Button>
            <br/>
            <br/>

        </template>
    </Dialog>

    <Dialog v-model:visible="showShare" :style="{width: '550px'}" :show-header="true" :closable="false"
            :modal="true"
    >

        <template #header>
            <h3>Invite another person to manage:<br/>{{ shareKey }}</h3>
        </template>
             <div class="p-fluid">
                <div class="p-field">
                    <label for="shareEmail">Enter email address </label>
                    <InputText type="text" id="shareEmail" v-model="shareEmail"
                               placeholder="Enter email address"
                    >

                    </InputText>
                </div>
            </div>
            <div v-if="shareLink"
                 style="overflow-wrap: anywhere;"
            >
                <Divider />
                <div class="p-mt-4">This link will only work for a pdfMachine key management account logged in as {{shareEmail}}.  The link is valid for 5 days. </div>
                <br/>{{shareLink}}
                <br/><Button icon="pi pi-copy" title="Copy to clipboard" class="p-button-rounded p-button-info "  style="position:relative;top:10px;"
                         @click="copyToClipboard"
                        :disabled = "loading"/>

                <div class="p-mt-4">Share this link to {{shareEmail}} to allow management of the
                    {{ shareKey }} key. {{shareEmail}} can paste this link into a browser to add the key to their portal.</div>
            </div>

        <template #footer>
           <Button label="Get Link"   @click="doShare"
                    :disabled="loading  || !shareEmail "
            />
            <Button
                    label="Close" :disabled="loading"
                    @click="closeShare"

            ></Button>
            <br/>
            <br/>

        </template>
    </Dialog>


</div>
</template>

<script>
import { onMounted, ref, reactive, watch} from 'vue'
import {FilterMatchMode} from "primevue/api";
import router from "@/router";
import { useToast } from "primevue/usetoast";
import {loadKeyManager, removeLicenseDetails, combineLicenseDetails, applyKeyToken, getShareLink} from '@/api/licenses'
import {state, exportCSV, fmtDate, validateEmail} from '@/utils.js'
import ManageKey from "@/components/ManageKey";
require('../copy-to-clipboard.js')



export default {
      components: {
        ManageKey
    }

    , setup()
    {
        const toast = useToast()
        const lics = reactive({})
        const licenses = reactive([])

        let showShare = ref(false)
        let shareKey = ref('')
        let shareEmail = ref('')
        let shareLink = ref('')

        let showCombine = ref(false)
        let combineKeys = reactive([])
        let combineExpiry = ref('')

        let filters = ref({
            global: {value: '', matchMode: FilterMatchMode.CONTAINS},
        });
        let addKeyDialog = ref(false)
        let loading = ref(false)

        onMounted(async () => {

            let keytoken = window.localStorage.getItem('keytoken');
            if (keytoken)
            {
                toast.add({severity: 'info', summary: 'Adding key', detail: "Please wait ...", life: 3000});
                loading.value = true
                await applyKeyToken(keytoken)
                if (state.keyManager && state.keyManager.error)
                    toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
                window.localStorage.removeItem('keytoken');
                loading.value = false

            }

            await loadKeyManager()

            if (state.keyManager.licenses)
                for (let l of state.keyManager.licenses)
                    licenses.push(l)



        });

        const resetLicenses = () => {
            licenses.splice(0, licenses.length)
            if (state.keyManager && state.keyManager.licenses)
                for (let l of state.keyManager.licenses)
                    licenses.push(l)

        }

        const share = (data) => {
            shareKey.value = data.code
            showShare.value = true
        }

        watch(shareEmail, () => {
                shareLink.value = ''
            })

        const exportLicenses = exportCSV;

            return {
                loading,
                filters,
                licenses,
                lics,
                fmtDate,
                addKeyDialog,
                toast,
                resetLicenses,
                exportLicenses,
                state,

                share,
                showShare,
                shareKey,
                shareEmail,
                shareLink,

                showCombine,
                combineKeys,
                combineExpiry
            }

    }

    , methods: {
        removeLicense(data)
        {
            this.$confirm.require({
                header: `Remove ${data.code}`,
                message: `Are you sure you wish to remove it from this portal?`,
                accept: async () => {
                    this.loading = true
                    await removeLicenseDetails(data.code)
                    if (state.keyManager && state.keyManager.error)
                        this.$toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
                    this.resetLicenses()
                    this.loading = false

                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },

        promptCombine(data)
        {
            this.showCombine = true
            this.combineKeys = [{code:data.code}]
            for (let k of data.vpKeys.split(','))
                this.combineKeys.push({code:k})
            this.combineExpiry = data.expiry
        }

        , async doCombineKeys()
        {
            let code = this.combineKeys[0].code
            let vpKeys = this.combineKeys.slice(1, this.combineKeys.length-1)
            await combineLicenseDetails(code, vpKeys)
            if (state.keyManager && state.keyManager.error)
                this.$toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
            else
                this.resetLicenses()
            this.loading = false
        }

        , async editKey(data)
        {
            await router.push({path:`/users`, query:{code: data.code}});
        }

        , async doShare()
        {
            if (!this.shareEmail || !this.shareKey)
                return this.$toast.add({severity: 'error', summary: 'Failed', detail: "You must have an email and a key", life: 3000});

            if (!validateEmail(this.shareEmail))
                return this.$toast.add({severity: 'error', summary: 'Failed', detail: "You must enter a valid email", life: 3000});

            this.loading = true
            await getShareLink(this.shareKey, this.shareEmail, 5)
            if (state.keyManager && state.keyManager.error)
                this.$toast.add({severity: 'error', summary: 'Failed', detail: state.keyManager.error, life: 3000});
            else
                this.shareLink = state.keyManager.link

            this.loading = false

        }

        , closeShare()
        {
            this.shareLink = ""
            this.shareKey = ""
            this.shareEmail = ""
            this.showShare=false

        }

        , closeCombine()
        {
            this.combineKeys = []
            this.showCombine=false

        }

        ,copyToClipboard()
        {
            window.copyTextToClipboard(this.shareLink)
            this.$toast.add({severity: 'info', summary: 'Copied', detail: "Copied to clipboard", life: 3000});
        }

        ,clearFilter()
        {
          this.filters['global'].value = ''

        }
    }
}
</script>

<style scoped>

</style>
