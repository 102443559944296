import {fetchJSONPost}  from './utils.js'


const login = async(username, password) => {

    if (!username || !password)
        return {error: 1, msg: "An Email Address and Password must be set"};

    let response = await fetchJSONPost("/login", {"email": username, "password": password});

    if (!response)
        return {error :"Unknown error from server"}

    return response;
}

const signup = async (username, password)  => {

    if (!username || !password)
        return {error: 1, msg: "An Email Address and Password must be set"};

    let response = await fetchJSONPost("/signup", {"email": username, "password": password});

    if (!response)
        return {error :"Unknown error from server"}

     return response;

}

const sendEmailValidation = async (email, password) => {

    let response = await fetchJSONPost("/send-validation-email", {"email": email, "password": password});

    if (!response)
        return {error :"Unknown error from server"}

    return response
}

const requestResetPassword = async(email) => {
    let response = await fetchJSONPost("/send-reset-password", {"email": email});

    if (!response)
        return {error :"Unknown error from server"}

    return response;

}

const resetPassword = async(resettok, password, checkOnly) => {
    let response = await fetchJSONPost("/reset-password", { "resettok" : resettok, "password" :  password , "checkOnly" : checkOnly});

    if (!response)
        return {error :"Unknown error from server"}

    return response;


}

const validateEmail = async (t) => {

    let response = await fetchJSONPost("/validate-email/"+t);

    if (!response)
        return {error :"Unknown error from server"}

    return response;
}


export  {
    login,
    signup,
    sendEmailValidation,
    requestResetPassword,
    resetPassword,
    validateEmail
}
